code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
container {
  width: '90%';
}
body {
  background: #0e101c;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
form {
  max-width: 500px;
  margin: 0 auto;
}

h1 {
  font-weight: 100;
  text-align: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(79, 98, 148);
}
.nav-link {
  color: #2837a7 !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}
.dropdown-menu{
  background-color: rgba(80,80,80,.8) !important;
}
.dropdown-item{
  color: #FFFFFF !important;
  font-weight: 700 !important;
  text-decoration: none !important;
}
.dropdown-item:hover {
  color: #2837a7 !important;
}
.form {
  background: #0e101c;
  max-width: 400px;
  margin: 0 auto;
}

input {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid white;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 14px;
}

label {
  line-height: 2;
  text-align: left;
  display: block !important;
  margin-bottom: 13px;
  margin-top: 20px;
  color: #0e101c;
  font-size: 14px;
  font-weight: 200;
}


input:disabled {
  opacity: 0.4;
}

.App {
  max-width: 600px;
  margin: 0 auto;
}


hr {
  margin-top: 30px;
}

i {
  color: white;
}

span {
  color: tomato;
}
@media only screen and (max-width: 991px) {
  .navbar-nav-scroll {
    background: #515cdd;
    align-items: center;
  }
  .nav-link{
    color: #FFFFFF !important;
    font-weight: 700 !important;
    text-decoration: none !important;
  }
  .dropdown-menu{
    background-color: #515cdd !important;
  }
}
